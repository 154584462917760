import pimg1 from '../images/project/img-1.jpg'
import pimg2 from '../images/project/img-2.jpg'
import pimg3 from '../images/project/img-3.jpg'

import ps1img1 from '../images/project-single/1.jpg'
import ps1img2 from '../images/project-single/2.jpg'
import ps1img3 from '../images/project-single/3.jpg'

import psub1img1 from '../images/project-single/p1.jpg'
import psub1img2 from '../images/project-single/p2.jpg'


const Projects = [
    {
       Id:'1',
       pImg:pimg1,
       ps1img:ps1img1,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'JABATAN PENGAIRAN DAN SALIRAN',  
      //  subTitle:'Business Consulting',   
    },
    {
       Id:'2',
       pImg:pimg2,
       ps1img:ps1img2,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'PLAN MALAYSIA', 
      //  subTitle:'Interior Design',    
    },
    {
      Id:'3',
      pImg:pimg3,
      ps1img:ps1img3,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'PUSAT GEOSPATIAL NEGARA',  
      // subTitle:'Business Consulting',   
   },
   
    
]

export default Projects;