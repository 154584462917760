import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import ProductList from '../../components/ProductList/ProductList.js'
import Navbar3 from '../../components/Navbar3/Navbar3';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const ProductPage =() => {
    return(
        <Fragment>
            <Navbar3/>
            <PageTitle pageTitle={'Partners'} pagesub={'Products'}/> 
            <ProductList/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ProductPage;

