import React from 'react';
import Fab from '@material-ui/core/Fab';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar/BlogSidebar.js'
import VideoModal from '../ModalVideo/VideoModal'
import NavigationIcon from '@material-ui/icons/Navigation';
import products from '../../api/product'
// import Divider from '@material-ui/core/Divider';
import { Divider, withStyles } from '@material-ui/core';

const BoldDivider = withStyles({
  root: {
    fontWeight: 'bold',
    height: 3, // increase the height to make it thicker
    backgroundColor: '#000000', // change the color as needed
  },
})(Divider);
const ClickHandler = () =>{
    window.scrollTo(10, 0);
 }



const ProductList = (props) => {
    return(
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            {products.map((product, bitem) => (
                                <div className={`post  ${product.blClass}`}key={bitem}>
                                    <div className="entry-media video-holder">
                                        <img src={product.blogSingleImg} alt=""/>
                                        <VideoModal/>
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            {/* <li><i className="fi flaticon-user"></i> By <Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{blog.authorTitle}</Link> </li> */}
                                            {/* <li><i className="fi flaticon-comment-white-oval-bubble"></i> Comments {blog.comment} </li> */}
                                            {/* <li><i className="fi flaticon-calendar"></i> {product.create_at}</li> */}
                                        </ul>
                                    </div>
                                    <div className="entry-details">
                                        <h3><a href={product.linkmain} target="_blank">{product.title}</a></h3>
                                        <p>{product.paragraph}</p>
                                        {product && product.link1 && (
                                            <a href={product.linkprod + product.link1} target="_blank">
                                            <Fab variant="extended" style={{ backgroundColor: '#ff4a17' }}>
                                                <NavigationIcon/>
                                                {product.link1}
                                            </Fab>
                                            </a>
                                        )}
                                        <div>&nbsp;</div>   
                                        {product && product.link2 && (
                                        <a href={product.linkprod + product.link2} target="_blank">
                                        <Fab variant="extended" style={{ backgroundColor: '#ff4a17' }}>
                                            <NavigationIcon/>
                                            {product.link2}
                                        </Fab>
                                        </a>
                                        )}
                                        <div>&nbsp;</div>   
                                        {product && product.link3 && (
                                        <a href={product.linkprod + product.link3} target="_blank">
                                        <Fab variant="extended" style={{ backgroundColor: '#ff4a17' }}>
                                            <NavigationIcon/>
                                            {product.link3}
                                        </Fab>
                                        </a>
                                        )}
                                        <div>&nbsp;</div>   
                                        {product && product.link4 && (
                                        <a href={product.linkprod + product.link4} target="_blank">
                                        <Fab variant="extended" style={{ backgroundColor: '#ff4a17' }}>
                                            <NavigationIcon/>
                                            {product.link4}
                                        </Fab>
                                        </a>
                                        )}
                                        <div>&nbsp;</div>   
                                        {product && product.link5 && (
                                        <a href={product.linkprod + product.link5} target="_blank">
                                        <Fab variant="extended" style={{ backgroundColor: '#ff4a17' }}>
                                            <NavigationIcon/>
                                            {product.link5}
                                        </Fab>
                                        </a>
                                        )}
                                        <div>&nbsp;</div>  
                                    </div>
                                    <BoldDivider />
                                </div>
                                
                            ))}

                            {/* <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link to="/blog-left-sidebar" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active"><Link to="/blog-left-sidebar">1</Link></li>
                                    <li><Link to="/blog-left-sidebar">2</Link></li>
                                    <li><Link to="/blog-left-sidebar">3</Link></li>
                                    <li>
                                        <Link to="/blog-left-sidebar" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft}/>
                </div>
            </div>
        </section>

     )
        
}

export default ProductList;
