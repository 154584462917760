import React from 'react';
//import ContactForm from '../ContactFrom/ContactForm'
import FormSharepoint from '../FormSharepoint/FormSharepoint';






const Contactpage = () => {

    return(
        <>
      
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-place"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Address</h2>
                                            <p>A-G-2, Diamond Residences, Jalan Semarak Api 3, 
                                                Off, Jln Gombak, 53000 Kuala Lumpur.</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email Us</h2>
                                            <p>info@geospatials.com.my</p>
                                            <p>techsupport@geospatials.com.my</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Call Now</h2>
                                            <p>+603 4021 3690</p>
                                            {/* <p>+603 4021 6690</p> */}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>Have Any Question?</h2>
                            <p>To ensure we can assist you quickly, please complete the form below. 
                            Once received, an Geo Spatial Solutions specialist will be in touch to discuss your requirements.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <FormSharepoint/>
                        </div>
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe  src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ06qzAiNIzDERFO_iUTPQXEE&key=AIzaSyBQZkTMACX-lAUtvijKuxI_1URTh4iim8s"></iframe>
                </div>
            </section>
        </section>
        </>
     )
        
}

export default Contactpage;
