import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import BeatLoader from 'react-spinners/BeatLoader';

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    phone: "",
    service: "", // Renamed from 'subject' to 'service' to match SharePoint column
    message: "",
  });
  

  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const [isLoading, setIsLoading] = useState(false);

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    if (validator.allValid()) {
      // Prepare the data in the format expected by your Power Automate Flow
      const formData = {
        name: forms.name,
        email: forms.email,
        phone: forms.phone,
        service: forms.service,
        message: forms.message,
      };

      // URL from Power Automate Flow
      const flowUrl = process.env.REACT_APP_FLOW_URL;

      // Send the data using fetch or any other HTTP client
      fetch(flowUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(
                `Network response was not ok (${response.status} ${response.statusText})`
            );
          }
          return response.json();
        })

        .then((data) => {
         // console.log("Success:", data);
          toast.success("Form submitted successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          // Reset the form or show a success message
          setForms({
            name: "",
            email: "",
            phone: "",
            service: "",
            message: "",
          });
          setIsLoading(false); // Stop loading after fetch is complete
        })
        .catch((error) => {
         // console.error("Error:", error);
          toast.error("Failed to submit form.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // Handle the error
          setIsLoading(false); // Stop loading if an error occurs
        });

      validator.hideMessages();
    } else {
      validator.showMessages();
      setIsLoading(false); // Stop loading if validation fails
    }
  };

  return (
    <>
      <form
        onSubmit={(e) => submitHandler(e)}
        className="contact-validation-active"
      >
        <div className="row">
          {/* Name Input */}
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <input
                value={forms.name}
                type="text"
                name="name"
                onBlur={changeHandler}
                onChange={changeHandler}
                placeholder="Your Name"
              />
              {validator.message("name", forms.name, "required|alpha_space")}
            </div>
          </div>
          {/* Email Input */}
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <input
                value={forms.email}
                type="email"
                name="email"
                onBlur={changeHandler}
                onChange={changeHandler}
                placeholder="Your Email"
              />
              {validator.message("email", forms.email, "required|email")}
            </div>
          </div>
          {/* Phone Input */}
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <input
                value={forms.phone}
                type="phone"
                name="phone"
                onBlur={changeHandler}
                onChange={changeHandler}
                placeholder="Your Phone"
              />
              {validator.message("phone", forms.phone, "required|phone")}
            </div>
          </div>
          {/* Service Selection */}
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <select
                onBlur={changeHandler}
                onChange={changeHandler}
                value={forms.service}
                name="service"
              >
                <option value="">Choose a Service</option>
                <option value="Esri">Esri</option>
                <option value="Hexagon">Hexagon</option>
                <option value="Davis">Davis</option>
                <option value="Others">Others</option>
              </select>
              {validator.message("service", forms.service, "required")}
            </div>
          </div>
          {/* Message Input */}
          <div className="col col-lg-12 col-12">
            <textarea
              onBlur={changeHandler}
              onChange={changeHandler}
              value={forms.message}
              name="message"
              placeholder="Message"
            ></textarea>
            {validator.message("message", forms.message, "required")}
          </div>
        </div>
        <div className="submit-area">
          <button type="submit" className="theme-btn" disabled={isLoading}>
          {isLoading ? <BeatLoader size={5} color={"#FFFFFF"} /> : 'Submit'}
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
