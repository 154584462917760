import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'
import simg5 from '../images/service/4.jpg'
import simg6 from '../images/service/4.jpg'
import simg7 from '../images/service/4.jpg'
import simg8 from '../images/service/4.jpg'
import simg9 from '../images/service/4.jpg'
import simg10 from '../images/service/4.jpg'

import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'
import sImgS5 from '../images/service-single/img-4.jpg'
import sImgS6 from '../images/service-single/img-4.jpg'
import sImgS7 from '../images/service-single/img-4.jpg'
import sImgS8 from '../images/service-single/img-4.jpg'
import sImgS9 from '../images/service-single/img-4.jpg'
import sImgS10 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'

import icon1 from '../images/gps-Copy.png'
import icon2 from '../images/service-Copy.png'
import icon3 from '../images/cartography-Copy.png'
import icon4 from '../images/remote-Copy.png'
import icon5 from '../images/mapping-Copy.png'
import icon6 from '../images/database-Copy.png'
import icon7 from '../images/plotting.png'
import icon8 from '../images/consulting-Copy.png'
import icon9 from '../images/appDev-Copy.png'
import icon10 from '../images/lidar.png'


const Services = [
    {
        Id: '1',
        sImg:simg,
        sImgS:sImgS1,
        sTitle: 'Total Station & GPS Survey',
        description:'Used in land surveying to collect accurate measurements of land features, boundaries, and topography.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon1,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:simg2,
        sImgS:sImgS2,
        sTitle: 'GIS Solution, Services & Training',
        description:'GIS solutions, services, and training are essential tools for managing and analyzing spatial data, and they are widely used in a range of industries, including government, healthcare, natural resources, utilities, and transportation.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon2,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sImgS:sImgS3,
        sTitle: 'Digital Cartography',
        description:'Facilitated the creation, sharing, and analysis of geographic data, revolutionising the profession of mapping and enhancing our ability to make decisions and comprehend our surroundings.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon3,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sImgS:sImgS4,
        sTitle: 'Remote Sensing',
        description:'Process of gathering information about the environment or Earth surface from a distance, without direct physical contact. This is done through the use of various technologies such as satellites, aircraft, drones, or ground-based sensors.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon4,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:simg5,
        sImgS:sImgS5,
        sTitle: 'Facility Management / Automated Mapping',
        description:'Overall, facility management and automated mapping are complementary practices that can help organizations to optimize their physical infrastructure, improve efficiency, and reduce costs.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon5,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg6,
        sImgS:sImgS6,
        sTitle: 'Database Design, Development & Conversion',
        description:'Process of creating and managing databases, which are collections of data that are organized in a structured manner for efficient storage, retrieval, and manipulation.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '7',
        sImg:simg7,
        sImgS:sImgS7,
        sTitle: 'Scanning, Plotting & Printing Services',
        description:'All essential document management services that involve the use of specialized equipment to create physical or digital copies of documents, images, or designs.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon7,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '8',
        sImg:simg8,
        sImgS:sImgS8,
        sTitle: 'Geospatial & IT Services Consulting',
        description:'Essential for organizations that require specialized expertise to plan, develop, and implement geo-spatial projects.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon8,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '9',
        sImg:simg9,
        sImgS:sImgS9,
        sTitle: 'Application Development',
        description:'Process of creating software applications that use geographic information system (GIS) data to perform specific tasks or functions.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon9,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '10',
        sImg:simg10,
        sImgS:sImgS10,
        sTitle: 'Photogrammetry',
        description:'It involves the use of specialized software to interpret photographic images and create 3D models, maps, and other spatial data.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:icon10,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    }
]    

export default Services;