import React from 'react'
import simg from '../../images/mission-shape.png'


const MissionVission = (props) => {
    return (
        <section className="wpo-mission-vission-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-mission-item">
                            <div className="wpo-mission-text">
                                <h2>Our Mission</h2>
                                <h5>Our mission at Geo Spatial Solutions Sdn Bhd is to provide 
                                    advanced and reliable geo spatial solutions that help clients 
                                    make informed decisions, optimize their resources and improve 
                                    efficiency, while being at the forefront of innovation in the industry.</h5>
                            </div>
                            <div className="shape-1"></div>
                            <div className="shape-2"><img src={simg} alt=""/></div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-vission-item">
                            <div className="wpo-vission-text">
                                <h2>Our Vision</h2>
                                <h5>To be a world leader in innovative and reliable geospatial 
                                    and non-geospatials solutions, using the latest technologies 
                                    to help our clients make informed decisions, optimize resources, 
                                    and improve quality of life for people and communities globally.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MissionVission;