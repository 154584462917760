// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import blogSingleImg1 from "../images/blog/blog-single/img-1.jpg";
import blogSingleImg2 from "../images/blog/blog-single/img-2.jpg";
import blogSingleImg3 from "../images/blog/blog-single/img-3.jpg";




const blogs = [ 
    {
        id: '1',
        title: 'ESRI',
        screens: blogImg1,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Marilou Kelleher',
        authorTitle:'Sineor Consultant',
        create_at: '23 Feb,2023',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
        paragraph: 'Esri is the pioneer and global leader in Geographic Information System (GIS) technology. The Redlands, California-based company architected the assembly and analysis of geographic information - the science behind modern-day GIS - to inform landmark projects and develop leading-edge products.',
        linkmain: 'https://esrimalaysia.com.my',
        linkprod: 'https://esrimalaysia.com.my/',
        link1: 'arcgis-online',
        link2: 'arcgis-pro',
        link3: 'arcgis-enterprise',
        link4: 'esri-full-product-list',
    },
    {
        id: '2',
        title: 'HEXAGON',
        screens: blogImg2,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Konal Biry',
        authorTitle:'Creative Director',
        create_at: '23 Feb,2023',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
        paragraph:'Hexagon is a global leader in digital reality solutions, combining sensor, software and autonomous technologies. We are putting data to work to boost efficiency, productivity, quality and safety across industrial, manufacturing, infrastructure, public sector, and mobility applications.',
        linkmain: 'https://hexagon.com/company/divisions/geosystem',
        linkprod: 'https://hexagon.com/Products/',
        //link1: 'https://hexagonusfederal.com/what-we-do/products/geospatial-solutions-and-content/luciad-portfolio',
        link2: 'ERDAS-IMAGINE',
        link3: 'ERDAS-IMAGINE-Add-ons' ,
        link4: 'ERDAS-APOLLO',
        link5: 'erdas-extensions-arcgis',
    },
    {
        id: '3',
        title: 'DAVIS',
        screens: blogImg3,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Aliza Anny',
        authorTitle:'Art Director',
        create_at: '23 Feb,2023',
        blogSingleImg:blogSingleImg3, 
        comment:'95',
        blClass:'format-video',
        paragraph:'Davis Instruments created the personal weather station market over 30 years ago when we introduced the Digitar “Weather Pro.” Today, all over the globe, Davis Instruments is known for high quality, robust weather monitoring solutions that provide scientific precision at an affordable price for backyard weather enthusiasts, professional meteorologists and industrial applications.',
        linkmain: 'https://www.davisinstruments.com',
    },
    // {
    //     id: '4',
    //     title: 'DAVIS',
    //     screens: blogImg3,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    //     description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
    //     author: 'Aliza Anny',
    //     authorTitle:'Art Director',
    //     create_at: '23 Feb,2023',
    //     blogSingleImg:blogSingleImg3, 
    //     comment:'95',
    //     blClass:'format-video',
    //     paragraph:'Davis Instruments created the personal weather station market over 30 years ago when we introduced the Digitar “Weather Pro.” Today, all over the globe, Davis Instruments is known for high quality, robust weather monitoring solutions that provide scientific precision at an affordable price for backyard weather enthusiasts, professional meteorologists and industrial applications.',
    //     linkmain: 'https://www.davisinstruments.com',
    // },
];
export default blogs;