import React, {Fragment} from 'react';
import About3 from '../../components/about3/about3';
import Footer from '../../components/footer/Footer';
// import FunFact from '../../components/FunFact/FunFact';
import Hero6 from '../../components/hero6/Hero6';
import Navbar3 from '../../components/Navbar3/Navbar3';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSectionS3 from '../../components/ServiceSectionS3/ServiceSectionS3';
import PartnerSectionS2 from '../../components/PartnerSection2/PartnerSectionS2';
import Subscribe from '../../components/Subscribe/Subscribe';
import Logo from '../../images/logo.png';
import { Link } from 'react-router-dom';



const HomePage7 =() => { 
  //   const isUnderMaintenance = true; // Set this condition based on your maintenance status

  // if (isUnderMaintenance) {
  //   return <Link to='../../components/Maintenance/Maintenance' />;
  // }

  // Render your main component content
    return(
        <Fragment>
            <Navbar3 Logo={Logo} topbarNone={'wpo-header-style-6'}/>
            <Hero6/>
            <About3/>
            <ServiceSectionS3/>
            {/* <FunFact fClass={'wpo-fun-fact-section-s2 pt-120'}/> */}
            <Subscribe sbClass={'wpo-subscribe-section-s2 pt-0'}/>
            <PartnerSectionS2/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage7;