import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

toast.configure();

const Subscribe = (props) => {

    const [isLoading, setIsLoading] = useState(false); // State to track loading

    const SubmitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading
        const emailInput = e.target.elements.email; // the email input field
        const email = emailInput.value;

        const flowUrlSubscriber = process.env.REACT_APP_FLOW_URL_SUBSCRIBER;

            await fetch(flowUrlSubscriber, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email })
        })
        .then(response => {
            if (response.ok) {
                return response.json(); // Parse the JSON response
            } else {
                // Handle HTTP error
                throw new Error('Failed to subscribe');
            }
        })
        .then(data => {

            if(data.message === "Success"){

                // Handle success, such as displaying a success message
                toast.success('Subscription successful!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                emailInput.value = ''; // Clear the input field after successful submission
                setIsLoading(false); // Stop loading on success or failure
            }
        })
        .catch(error => {
            // Display error message
            toast.error(error.message || 'Error during subscription. Please try again later.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false); // Stop loading on error
        });
    }

    return (
        <section className={`wpo-subscribe-section section-padding ${props.sbClass}`}>
            <div className="container">
                <div className="wpo-subscribe-wrap">
                    <div className="subscribe-text">
                        <span>Subscribe here</span>
                        <h3>Subscribe For GSS</h3>
                    </div>
                    <div className="subscribe-form">
                        <form onSubmit={SubmitHandler}>
                            <div className="input-field">
                                <input type="email" name="email" placeholder="Enter your email" required   disabled={isLoading}/>
                                <button type="submit"  disabled={isLoading}>{isLoading ? 'Subscribing...' : 'Subscribe'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
           
        </section>
    )
}

export default Subscribe;
