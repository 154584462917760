import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import MissionVission from '../../components/MissionVission/MissionVission';
// import About4 from '../../components/about4/about4';
import Skill2 from '../../components/Skill2/Skill2';
// import Testimonial from '../../components/Testimonial/Testimonial';
// import FunFact from '../../components/FunFact/FunFact';
import Footer from '../../components/footer/Footer';


const AboutPage = () => {
    return (
        <Fragment>
            <Navbar3/>
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            <MissionVission/>
            {/* <About4/> */}
            <Skill2/>
            {/* <Testimonial/>
            <FunFact fClass={'wpo-fun-fact-section-s2 pt-0'}/> */}
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
